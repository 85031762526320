import "../App.css";
import { Link } from "react-router-dom";
import coffeeQuoteImage from "../images/icon.png"; // Replace with actual image
import appscreen from "../images/app.jpg"; // Replace with actual image

function FamousCoffeeQuotes() {
  return (
    <div className={"dark"}>
      <main className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>
            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>

          <div className="text-center p-10">
            <h2 className="text-5xl py-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Famous Coffee Quotes to Start Your Day
            </h2>
            <h3 className="text-2xl py-2 font-poppinsemi dark:text-white md:text-3xl">
              Fuel Your Mornings with a Sip of Wisdom
            </h3>
          </div>

          {/* Flex container for text and image */}
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between pt-20">
            <div className="lg:w-2/3">
              <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
                Why Coffee and Quotes Are the Perfect Pair
              </h3>
              <p className="font-poppinsemi text-gray-200 max-w-3xl">
                Coffee has long been the go-to drink for fueling creativity,
                productivity, and deep thinking. Pairing coffee with a great
                quote can give you that extra dose of inspiration to kickstart
                your day. Here are some of the most famous coffee quotes that
                will make your mornings a little brighter.
              </p>
            </div>
            {/* Image next to the text */}
            <div className="lg:w-1/3 lg:ml-6 mt-10 lg:mt-0">
              <div className="rounded-2xl overflow-hidden">
                <img
                  alt="appscreens"
                  src={appscreen}
                  className="object-contain w-full h-auto" // Restricts image size
                />
              </div>
            </div>
          </div>

          <div className="pt-20">
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              1. "But first, coffee."
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              This simple yet iconic quote captures the essence of how many
              people start their day—with coffee as the priority. It’s a gentle
              reminder that sometimes, all we need is a cup to get moving.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              2. "Coffee is a language in itself." – Jackie Chan
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              This quote by the legendary Jackie Chan speaks to how universal
              the love for coffee is. It's a language shared across cultures and
              generations—a drink that connects people.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              3. "I have measured out my life with coffee spoons." – T.S. Eliot
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              In this reflective quote, poet T.S. Eliot uses coffee as a
              metaphor for the passage of time, showcasing how something as
              simple as coffee becomes an integral part of life’s routine.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              4. "Even bad coffee is better than no coffee at all." – David
              Lynch
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              For the die-hard coffee lovers, David Lynch's words ring true.
              There’s no such thing as a bad coffee day when a cup can turn
              things around.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              5. "Coffee: the favorite drink of the civilized world." – Thomas
              Jefferson
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              Even centuries ago, coffee was revered by influential minds.
              Thomas Jefferson’s appreciation for coffee reveals its importance
              in shaping culture and society.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              6. "A yawn is a silent scream for coffee."
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              This humorous quote is all too relatable. A morning yawn is often
              a call for coffee, and nothing satisfies that call like the first
              sip of a strong brew.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              Conclusion
            </h3>
            <p className="font-poppinsemi max-w-3xl text-gray-200">
              Whether you're in need of motivation or just a smile, these famous
              coffee quotes are sure to lift your spirits. Next time you're
              sipping your morning brew, remember—coffee isn’t just a drink,
              it’s a source of inspiration.
            </p>
          </div>
        </section>

        <section>
          <div className="text-center pt-20 lg:flex lg:justify-between">
            <h3 className="text-center py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
            <div className="text-center lg:flex lg:justify-between">
              <Link
                to="/privacypolicy"
                className="py-1 text-white font-poppins"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default FamousCoffeeQuotes;
