import "../App.css";
import { Link } from "react-router-dom";
import intCoffee from "../images/intCoffee.PNG";

function InternationalCoffeeDay() {
  return (
    <div className={"dark"}>
      <main className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>
            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>

          <div className="text-center p-10">
            <h2 className="text-5xl py-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Celebrating International Coffee Day
            </h2>
            <h3 className="text-2xl py-2 font-poppinsemi dark:text-white md:text-3xl">
              A Global Tribute to Our Favorite Brew
            </h3>
          </div>
          <div className="relative mx-auto bg-gradient-to-b from-gray-800 rounded-3xl w-80 h-80 mt-5 overflow-hidden md:h-80 md:w-80">
            <img
              alt="intCoffee"
              src={intCoffee}
              layout="fill"
              objectFit="cover"
            />
          </div>

          <div className="pt-20">
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              The History of International Coffee Day
            </h3>
            <p className="font-poppinsemi text-gray-200">
              October 1st marks a special day for coffee lovers worldwide –
              International Coffee Day! Whether you sip on an espresso,
              cappuccino, or a smooth pour-over, this day is all about
              celebrating the joy, culture, and people behind our beloved
              coffee. Let’s dive into the history of this day and how you can
              join in the celebration. International Coffee Day was first
              launched in 2015 by the International Coffee Organization (ICO).
              This special day is meant to promote the fair trade movement and
              raise awareness of the issues coffee farmers face. From its humble
              beginnings, coffee has grown into a global industry that brings
              together millions of people, from farmers to baristas to coffee
              drinkers.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              5 Fun Facts About Coffee
            </h3>
            <ul className="list-disc pl-5 text-gray-200 font-poppinsemi">
              <li>
                Coffee is the second most traded commodity in the world—only
                behind oil.
              </li>
              <li>
                The world drinks over 2.25 billion cups of coffee every day.
              </li>
              <li>
                Finland is the biggest consumer of coffee per capita, drinking
                around 12 kilograms a year!
              </li>
              <li>
                The word "coffee" comes from the Arabic word "qahwa," originally
                used for wine.
              </li>
              <li>
                Espresso refers to a brewing method, not a specific coffee bean.
              </li>
            </ul>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              How to Celebrate International Coffee Day
            </h3>
            <p className="font-poppinsemi text-gray-200">
              Whether you’re a casual coffee drinker or a self-proclaimed coffee
              connoisseur, here are some ways to make the most of this
              caffeinated celebration:
            </p>
            <ul className="list-disc pl-5 font-poppinsemi text-gray-200">
              <li>
                <strong>Brew a New Coffee Style:</strong> Experiment with
                different brewing methods, from French press to AeroPress, and
                discover a new way to enjoy your coffee.
              </li>
              <li>
                <strong>Support Local Coffee Farmers:</strong> Buy fair-trade
                coffee or beans from local sustainable farms.
              </li>
              <li>
                <strong>Visit a New Café:</strong> Check out a new local coffee
                shop and try a specialty drink.
              </li>
              <li>
                <strong>Share Your Coffee Story on Social Media:</strong> Use
                hashtags like #InternationalCoffeeDay to share your coffee
                moments with the world.
              </li>
              <li>
                <strong>Host a Coffee Tasting Party:</strong> Invite friends
                over for a coffee-tasting session with different beans and
                roasts.
              </li>
            </ul>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              Quotes to Celebrate Coffee
            </h3>
            <ul className="list-none pl-5 font-poppinsemi text-gray-200">
              <li>“Coffee is a language in itself.” – Jackie Chan</li>
              <li>
                “I like coffee because it gives me the illusion that I might be
                awake.” – Lewis Black
              </li>
              <li>“Humanity runs on coffee.” – Unknown</li>
            </ul>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              Conclusion
            </h3>
            <p className="font-poppinsemi text-gray-200">
              As we sip on our favorite brews today, let’s take a moment to
              appreciate the coffee farmers, roasters, and baristas who make
              each cup so special. Happy International Coffee Day to coffee
              lovers everywhere—may your cup always be full, your beans always
              fresh, and your mornings always bright!
            </p>
          </div>
        </section>

        <section>
          <div className="text-center pt-20 lg:flex lg:justify-between">
            <h3 className="text-center py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
            <div className="text-center lg:flex lg:justify-between">
              <Link
                to="/privacypolicy"
                className="py-1 text-white font-poppins"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default InternationalCoffeeDay;
