import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { BsFillThreadsFill, BsInstagram } from "react-icons/bs";

export default function Contact() {
  return (
    <div className={"dark"}>
      <div className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>
            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-600 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="text-center font-poppins p-2">
            <h3 className="text-2xl py-2 font-poppinsemi text-orange-600 dark:text-orange-500 md:text-3xl">
              Hey there!
            </h3>
            <p className="text-md py-5 font-poppinsemi leading-8 text-gray-800  dark:text-gray-200  md:text-xl max-w-xl mx-auto">
              We are always up for a chat!
            </p>
            <p className="text-md py-5 leading-8 text-gray-800  dark:text-gray-200  md:text-xl max-w-xl mx-auto">
              We be reached at&nbsp;
              <span className="text-orange-600 dark:text-orange-500">
                bobevrosen@gmail.com
              </span>
            </p>
            <div className="relative mx-auto bg-gradient-to-b from-gray-800 rounded-3xl w-80 h-80 mt-5 overflow-hidden md:h-80 md:w-80">
              <img alt="logo" src={logo} layout="fill" objectFit="cover" />
            </div>
          </div>
          <div className="text-3xl lg:text-5xl flex justify-center gap-8 lg:gap-10 py-3 text-gray-600 dark:text-gray-400">
            <a href="https://www.instagram.com/espressoquotes/">
              <BsInstagram />
            </a>
            <a href="https://www.threads.net/@espressoquotes">
              <BsFillThreadsFill />
            </a>
          </div>
        </section>

        <section>
          <div className="text-center lg:flex lg:justify-between">
            <h3 className="text-l py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
          </div>
        </section>
      </div>
    </div>
  );
}
