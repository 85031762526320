import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

function Rederect() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.thebig01.coffeewishv3";
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/bg/app/espresso-quotes-sayings/id6449391056";
    } else {
      window.location.href = "https://espressoquotes.com";
    }
  }, []);

  return <div className="Download"></div>;
}

export default Rederect;
