import "../App.css";
import { Link } from "react-router-dom";
import appscreens from "../images/iphone front-2.png";

function MostPopularCoffeeTypes() {
  return (
    <div className={"dark"}>
      <main className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>
            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>

          <div className="text-center p-10">
            <h2 className="text-5xl py-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Most Popular Types of Coffee
            </h2>
            <h3 className="text-2xl py-2 font-poppinsemi dark:text-white md:text-3xl">
              Discover the Favorites Around the World
            </h3>
          </div>

          {/* Flex container for text and image */}
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between pt-20">
            <div className="lg:w-2/3">
              <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
                The Top Coffee Types You Need to Know
              </h3>
              <p className="font-poppinsemi text-gray-200 max-w-3xl">
                Whether you're grabbing a quick espresso or savoring a rich
                latte, coffee is a staple in many cultures. Here’s a look at
                some of the most popular coffee types that have captured hearts
                worldwide.
              </p>
            </div>
            {/* Image next to the text */}
            <div className="lg:w-1/3 lg:ml-6 mt-10 lg:mt-0">
              <div className="rounded-2xl overflow-hidden">
                <img
                  alt="appscreens"
                  src={appscreens}
                  className="object-contain w-full h-auto" // Restricts image size
                />
              </div>
            </div>
          </div>

          <div className="pt-20">
            {/* Additional Coffee Types content */}
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              1. Espresso
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              A small, but mighty, shot of coffee, espresso is the base for many
              coffee drinks. It’s brewed by forcing hot water through
              finely-ground coffee beans, producing a rich and concentrated
              flavor.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              2. Cappuccino
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              Made with equal parts espresso, steamed milk, and milk foam, the
              cappuccino is a popular Italian coffee drink. The creamy texture
              and balanced flavor make it a favorite for many coffee
              enthusiasts.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              3. Latte
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              A latte is a deliciously smooth coffee drink made with one or two
              shots of espresso and topped with steamed milk. The milk takes the
              edge off the espresso, making it a great option for those who
              prefer a lighter coffee flavor.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              4. Americano
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              Simple and strong, an Americano is made by diluting espresso with
              hot water. This drink is similar to drip coffee but has the
              distinct taste of espresso.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              5. Mocha
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              The mocha blends coffee and chocolate, creating a decadent drink
              that's perfect for chocolate lovers. It typically contains
              espresso, steamed milk, and chocolate syrup or powder.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              6. Macchiato
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              Meaning "stained" in Italian, a macchiato is an espresso "stained"
              with a dash of steamed milk. It’s the perfect balance between an
              espresso and a latte.
            </p>

            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-400">
              Conclusion
            </h3>
            <p className="font-poppinsemi max-w-6xl text-gray-200">
              Whether you're an espresso fan or love a creamy cappuccino,
              coffee's rich diversity ensures there's something for everyone.
              Next time you grab a cup, why not try something new?
            </p>
          </div>
        </section>

        <section>
          <div className="text-center pt-20 lg:flex lg:justify-between">
            <h3 className="text-center py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
            <div className="text-center lg:flex lg:justify-between">
              <Link
                to="/privacypolicy"
                className="py-1 text-white font-poppins"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default MostPopularCoffeeTypes;
