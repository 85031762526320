import "../App.css";
import { Link } from "react-router-dom";

// Sample blog posts data
const blogPosts = [
  {
    id: 1,
    title: "International Coffee Day",
    description:
      "International Coffee Day was first launched in 2015 by the International Coffee Organization (ICO). This special day is meant to promote the fair trade movement and raise awareness of the issues coffee farmers face.",
    link: "/blog/international-coffee-day",
  },
  {
    id: 2,
    title: "Most Popular Types of Coffee",
    description:
      "Whether you're grabbing a quick espresso or savoring a rich latte, coffee is a staple in many cultures. Here's a look at some of the most popular coffee types that have captured hearts worldwide.",
    link: "/blog/most-popular-coffee-types",
  },
  {
    id: 3,
    title: "Famous Coffee Quotes to Start Your Day",
    description:
      "Explore the best coffee quotes that have inspired generations of coffee lovers around the world.",
    link: "/blog/famous-coffee-quotes",
  },
];

function Blog() {
  return (
    <div className={"dark"}>
      <main className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>
            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="text-center py-10">
            <h2 className="text-5xl pt-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Espresso Blog
            </h2>
            <h3 className="text-2xl p-2 font-poppinsemi dark:text-white md:text-3xl">
              Let's talk about coffee and quotes
            </h3>
          </div>

          {/* Add class to adjust margin */}
          <h2 className="text-2xl py-2 font-poppinsemi dark:text-orange-400 md:text-3xl">
            Latest
          </h2>
          <div className="lg:mx-10 grid gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {blogPosts.map((post) => (
              <div
                key={post.id}
                className="text-justify shadow-lg p-5 rounded-xl my-10 dark:bg-white"
              >
                <div className="flex justify-center ">
                  <h1 className="text-3xl py-6 text-center font-poppinsemi">
                    {post.title}
                  </h1>
                </div>
                <p className="font-poppinsemi">{post.description}</p>
                <div className="text-center mt-4">
                  <Link
                    to={post.link}
                    className="text-xl text-orange-500 font-poppinsemi underline"
                  >
                    Read more
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section>
          <div className="text-center lg:flex lg:justify-between">
            <h3 className="text-center py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
            <div className="text-center lg:flex lg:justify-between">
              <Link
                to="/privacypolicy"
                className="py-1 text-white font-poppins"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Blog;
