import "../App.css";
import logo from "../images/logo.png";
import googleplay from "../images/get-it-google-play.png";
import applestore from "../images/download-appstore.png";
import appscreens from "../images/iphone front.png";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

function Home() {
  return (
    <div className={"dark"}>
      <main className="px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/">
              <h1 className="text-l md:text-xl lg:text-3xl font-poppinsemi">
                espressoquotes
              </h1>
            </Link>

            <ul className="flex items-center">
              <li>
                <Link
                  to="/blog"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-l md:text-xl lg:text-xl text-orange-500 px-4 py-2 font-poppinsemi rounded-md dark:text-orange-500"
                >
                  contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="text-center p-10">
            <h2 className="text-5xl py-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Espresso Quotes & Sayings
            </h2>
            <h3 className="text-2xl py-2 font-poppinsemi dark:text-white md:text-3xl">
              Enjoy every coffee moment
            </h3>
          </div>
          <div className="relative mx-auto bg-gradient-to-b from-gray-800 rounded-3xl w-80 h-80 mt-5 overflow-hidden md:h-80 md:w-80">
            <img alt="logo" src={logo} layout="fill" objectFit="cover" />
          </div>
          <div className="text-center">
            <h2 className="text-5xl py-2 font-oswaldmedium text-orange-400 font-medium dark:text-orange-400 md:text-6xl">
              Coffee Lovers:
              <CountUp end={754} />
            </h2>
          </div>
        </section>
        {/*
    section two
    */}

        <section>
          <div>
            <h3 className="text-3xl py-5 text-center font-poppinsemi text-orange-600 dark:text-orange-500">
              Did you know?
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 lg:mx-80 dark:text-gray-200">
              Espresso, the bold and concentrated Italian-style coffee, gets its
              name from the method used to create it—pressed out quickly,
              "pronto," for the consumer. This rich brew, first crafted in Italy
              around the early 1900s, has since become the foundation for
              popular beverages like cappuccinos, lattes, and macchiatos. As
              National Espresso Day approaches on November 23, let’s raise a
              cup—or better yet, a shot—of espresso to celebrate the journey of
              this complex and flavorful creation!
            </p>
          </div>

          <div>
            <h3 className="text-3xl py-5 text-center font-poppinsemi text-orange-600 dark:text-orange-500">
              About the app:
            </h3>

            {/* Flexbox container to align text and image side by side */}
            <div className="lg:flex lg:items-start lg:justify-between lg:mx-80 space-y-6 lg:space-y-0">
              <div className="lg:w-2/3">
                <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
                  Welcome to the world of Quotations and Sayings. Do you feel
                  tired in the morning? Unlock the power of positive and funny
                  words. Reading inspiration thoughts every day can change your
                  life. Explore a vast collection of witty, wise, and funny
                  sayings from renowned authors, celebrities, and coffee
                  enthusiasts worldwide.
                </p>

                <h3 className="text-3xl py-5 text-center font-poppinsemi text-orange-600 dark:text-orange-500">
                  Key Features:
                </h3>
                <p className="text-md py-2 font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
                  - Receive daily notifications.
                  <br />
                  - Download and easily share your favourite ones with friends
                  and family.
                  <br />
                  - Enjoy unrestricted access to all features.
                  <br />
                  - Suggest quotes that will inspire others.
                  <br />
                  - Download your favorites as images.
                  <br />
                  - Copy text from quotes.
                  <br />- Like your favourite sayings.
                </p>
              </div>

              {/* Image container next to text */}
              <div className="lg:w-1/3 lg:ml-6">
                <div className="relative mx-auto rounded-2xl overflow-hidden">
                  <img
                    alt="appscreens"
                    src={appscreens}
                    className="object-contain w-full h-auto" // Restricts image size
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="lg:mx-64 lg:flex gap-10">
            <div className="text-justify shadow-lg p-5 rounded-xl my-10 dark:bg-white flex-1">
              <div className="flex gap-5 justify-center ">
                <h1 className="text-3xl py-6 text-center font-poppinsemi">
                  Try Espresso Quotes & Sayings
                </h1>
              </div>
              <p className="font-poppinsemi">
                Taste of inspiration with every cup of coffee! Espresso Quotes &
                Sayings is your companion for starting each day on a positive
                note. With our carefully curated quotes about life, happiness,
                wisdom, and more, we aim to inspire and uplift you.
              </p>
              <div className="flex gap-10 py-2 justify-center ">
                <a href="https://play.google.com/store/apps/details?id=com.thebig01.coffeewishv3">
                  <img alt="design" src={googleplay} width={192} height={56} />
                </a>
                <a href="https://apps.apple.com/bg/app/coffee-break-quotes-daily/id6449391056">
                  <img alt="design" src={applestore} width={162} height={56} />
                </a>
              </div>
            </div>
          </div>
          <div className="text-center p-5">
            <p className="text-md py-5 font-poppins leading-8 text-gray-800  dark:text-gray-200  md:text-xl max-w-xl mx-auto">
              Check out our projects on&nbsp;
              <span className="text-orange-500 dark:text-orange-500">
                <a href="https://apps.apple.com/bg/app/coffee-break-quotes-daily/id6449391056">
                  AppStore
                </a>
              </span>
              ,&nbsp;
              <span className="text-orange-500 dark:text-orange-500">
                <a href="https://play.google.com/store/apps/dev?id=9138583617337599888">
                  GooglePlay&nbsp;
                </a>
              </span>
              or email us.
            </p>
          </div>
        </section>
        <section>
          <div className="text-center lg:flex lg:justify-between">
            <h3 className="text-center py-1 font-poppins dark:text-white">
              © 2024 espressoquotes
            </h3>
            <div className="text-center lg:flex lg:justify-between">
              <Link
                to="/privacypolicy"
                className="py-1 text-white font-poppins"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
