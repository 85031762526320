import "../App.css";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Privacy() {
  const [darkMode, setDarkMode] = useState();

  const data = {
    darkModepage: darkMode,
  };

  return (
    <div className={darkMode ? "dark" : "dark"}>
      <main className="bg-white px-10 md:px-20 lg:px-40 dark:bg-zinc-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Link to="/" state={data}>
              <h1 className="text-3xl font-poppinsemi">espressoquotes</h1>
            </Link>
          </nav>

          <div>
            <h3 className="text-3xl py-5 text-center font-poppinsemi text-orange-600 dark:text-orange-500">
              Privacy Policy
            </h3>
            <p className="text-md font-poppins text-left leading-7 text-gray-800  dark:text-gray-200">
              This privacy policy applies to the Espresso Quotes & Sayings app
              (hereby referred to as "Application") for mobile devices that was
              created by Rosen Bobev (hereby referred to as "Service Provider")
              as a Free service. This service is intended for use "AS IS".
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Information Collection and Use
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
              The Application collects information when you download and use it.
              This information may include information such as<br></br>
              <li>Your device's Internet Protocol address (e.g. IP address)</li>
              <li>
                The pages of the Application that you visit, the time and date
                of your visit, the time spent on those pages
              </li>
              <li>The time spent on the Application</li>
              <li>The operating system you use on your mobile device</li>
              <br></br>
              The Application does not gather precise information about the
              location of your mobile device. <br></br> <br></br>For a better
              experience, while using the Application, the Service Provider may
              require you to provide us with certain personally identifiable
              information. The information that the Service Provider request
              will be retained by them and used as described in this privacy
              policy.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Third Party Access
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
              The app does use third party services that may collect information
              used to identify you. Below are the links to the Privacy Policy of
              the third-party service providers used by the Application:
              <ul>
                <li className="text-orange-600">
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Play Services
                  </a>
                </li>
                <li className="text-orange-600">
                  <a
                    href="https://support.google.com/admob/answer/6128543?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AdMob
                  </a>
                </li>
              </ul>
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Opt-Out Rights
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
              You can stop all collection of information by the Application
              easily by uninstalling it. You may use the standard uninstall
              processes as may be available as part of your mobile device or via
              the mobile application marketplace or network.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Data Retention Policy
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
              The Service Provider will retain User Provided data for as long as
              you use the Application and for a reasonable time thereafter. If
              you'd like them to delete User Provided Data that you have
              provided via the Application, please contact them at
              bobevrosen@gmail.com and they will respond in a reasonable time.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Children
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800  dark:text-gray-200">
              The Service Provider does not use the Application to knowingly
              solicit data from or market to children under the age of 13. The
              Application does not address anyone under the age of 13. The
              Service Provider does not knowingly collect personally
              identifiable information from children under 13 years of age. In
              the case the Service Provider discover that a child under 13 has
              provided personal information, the Service Provider will
              immediately delete this from their servers. If you are a parent or
              guardian and you are aware that your child has provided us with
              personal information, please contact the Service Provider
              (bobevrosen@gmail.com) so that they will be able to take the
              necessary actions.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Security
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800  dark:text-gray-200">
              The Service Provider is concerned about safeguarding the
              confidentiality of your information. The Service Provider provides
              physical, electronic, and procedural safeguards to protect
              information the Service Provider processes and maintains. But
              remember that no method of transmission over the internet, or
              method of electronic storage is 100% secure and reliable, and
              Service Provider cannot guarantee its absolute security.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Changes
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800 dark:text-gray-200">
              This Privacy Policy may be updated from time to time for any
              reason. The Service Provider will notify you of any changes to the
              Privacy Policy by updating this page with the new Privacy Policy.
              You are advised to consult this Privacy Policy regularly for any
              changes, as continued use is deemed approval of all changes.
              <br></br>
              This privacy policy is effective as of 2024-07-30
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Your Consent
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800  dark:text-gray-200">
              By using the Application, you are consenting to the processing of
              your information as set forth in this Privacy Policy now and as
              amended by us.
            </p>
            <h3 className="text-3xl py-5 text-left font-poppinsemi text-orange-600 dark:text-orange-500">
              Contact Us
            </h3>
            <p className="text-md font-poppins text-justify leading-7 text-gray-800  dark:text-gray-200">
              If you have any questions regarding privacy while using the
              Application, or have questions about the practices, please contact
              us:
              <li>By email at bobevrosen@gmail.com </li>
              <li>
                By visiting this page on our website: espressoquotes.com/contact
              </li>
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}
