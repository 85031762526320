import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Rederect from "./pages/Rederect";
import Blog from "./pages/Blog";
import Privacy from "./pages/PrivacyPolicy";
import InternationalCoffeeDay from "./pages/InternationalCoffeeDay";
import MostPopularCoffeeTypes from "./pages/MostPopularCoffeeTypes";
import FamousCoffeeQuotes from "./pages/FamousCoffeeQuotes";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/blog/international-coffee-day"
          element={<InternationalCoffeeDay />}
        />
        <Route
          path="/blog/most-popular-coffee-types"
          element={<MostPopularCoffeeTypes />}
        />
        <Route
          path="/blog/famous-coffee-quotes"
          element={<FamousCoffeeQuotes />}
        />
        <Route path="/download" element={<Rederect />} />
        <Route path="/privacypolicy" element={<Privacy />} />
      </Routes>
    </>
  );
};

export default App;
